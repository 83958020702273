export default {
  id: '93dee3eb84',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page form-page">\r\n    <div class="navbar no-shadow paymentPage">\r\n      <div class="navbar-bg"></div>\r\n      <div class="navbar-inner">\r\n        <div class="left">\r\n          <a href="#" class="link back ripple-color-red">\r\n            <img class="" src="assets/icons/back.png" width="35"/>\r\n          </a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  <div class="page-content" id="page-content">\r\n      <div class="block-container">\r\n        <div class="reg-txt-content">\r\n          <h2 class="margin-left fw-bold">Looking for business loan?</h2>\r\n          <h3 class="margin-left fw-bold">Get upto Rs.30 lacs</h3>\r\n          <span class="margin-left badge badge-ptf bg-color-blue text-color-white btn-shine-cm">Coming soon!</span>\r\n        </div>\r\n        <div class="text-center">\r\n          <img src="assets/gif/business-loan.gif" style="margin-top: 25px; width: 80%"/>\r\n        </div>\r\n        <div class="block">\r\n          <h3 class="text-color-blue-light fs-12 fw-600">Rest assured, our team is diligently working to process your request and provide you with our service as quickly as possible. \r\n            If you have any questions or concerns, don\'t hesitate to reach out to us.</h3>\r\n          <a href="/" class="col m-auto button button-fill button-large button-fill bg-color-red-light text-color-red btn-shine" style="top: 50px;">Back to home</a>\r\n        </div>\r\n      </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};