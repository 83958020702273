import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';
import regeneratorRuntime from "regenerator-runtime";
import DeviceDetector from "device-detector-js";
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/font-awesome.min.css';
import '../css/app.css';
import '../css/custom.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';
var baseURL = "https://ptfapi.rbsapi.co.in/v2/";
const app_version = '1.1.4';
const curr_env = 'production';
var app = new Framework7({
  root: '#app', // App root element
  id: 'com.phonetouchfinance.app', // App bundle ID
  name: 'Phone Touch Finance', // App name
  theme: 'md', // Automatic theme detection
  //app pushstate
  view: {
    pushState: true,
    pushStateSeparator: '#!',
    pushStateOnLoad: true,
    preloadPreviousPage: false,
  },
  smartSelect: {
    closeOnSelect: true,
  },
  sheet: {
    closeOnEscape: true,
    closeByBackdropClick: false
  },
  popup: {
    closeOnEscape: true,
  },
  popover: {
      closeOnEscape: true,
  },
  actions: {
      closeOnEscape: true,
  },
  navbar: {
    hideOnPageScroll: false,
  },
  // App root methods
  methods: {
    getDeviceUUID: function() {
      if (app.device.cordova) {
        return device.uuid;
      } else {
        return new DeviceUUID().get();
      }
    },
    initImageUpload: function(box) {
      let uploadField = box.querySelector('.image-upload');
        
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
        
        function previewImage(file){
          let thumb = box.querySelector('.js--image-preview'),
              reader = new FileReader();
      
          reader.onload = function() {
            thumb.style.backgroundImage = 'url(' + reader.result + ')';
          }
          reader.readAsDataURL(file);
          thumb.className += ' js--no-default';
        }
      
        function checkType(file){
          let imageType = /image\/.*|application\/pdf/;
          //var size=(file.size)/1000;
          if (!file.type.match(imageType)) {
            app.toast.show({
              text: 'Invalid file format!',
              closeTimeout: 3000,
            });
            throw 'Invalid file format';
          } else if (!file){
            app.toast.show({
              text: 'No file found!',
              closeTimeout: 3000,
            });
            throw 'No file found';
          } else {
            previewImage(file);
          }
        }
    },
    initDropEffect: function(box) {
      let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
          
      // get clickable area for drop effect
      area = box.querySelector('.js--image-preview');
      area.addEventListener('click', fireRipple);
      
      function fireRipple(e){
        area = e.currentTarget
        // create drop
        if(!drop){
          drop = document.createElement('span');
          drop.className = 'drop';
          this.appendChild(drop);
        }
        // reset animate class
        drop.className = 'drop';
        
        // calculate dimensions of area (longest side)
        areaWidth = getComputedStyle(this, null).getPropertyValue("width");
        areaHeight = getComputedStyle(this, null).getPropertyValue("height");
        maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));
    
        // set drop dimensions to fill area
        drop.style.width = maxDistance + 'px';
        drop.style.height = maxDistance + 'px';
        
        // calculate dimensions of drop
        dropWidth = getComputedStyle(this, null).getPropertyValue("width");
        dropHeight = getComputedStyle(this, null).getPropertyValue("height");
        
        // calculate relative coordinates of click
        // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
        x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
        y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
        
        // position drop and animate
        drop.style.top = y + 'px';
        drop.style.left = x + 'px';
        drop.className += ' animate';
        e.stopPropagation();
        
      }
    },
    initFileUpload: function() {
      var inputs = document.querySelectorAll( '.inputfile' );
        Array.prototype.forEach.call( inputs, function( input )
        {
          var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

          input.addEventListener( 'change', function( e )
          {
            var fileName = '';
            if( this.files && this.files.length > 1 )
              fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
              fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
              label.querySelector( 'span' ).innerHTML = fileName;
            else
              label.innerHTML = labelVal;
          });
        });
    },
    getJSON: function( url, callback) {
      $.getJSON(url, function(data) {
        callback(data);
      });
    },
    request: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    post: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        mimeType: "multipart/form-data",
        contentType: false,
        processData: false,
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserData: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {userinfo: true, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserDataLoan: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {loaninfo: true, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserDataInvest: function(success, error) {
      $.ajax({
        url: baseURL+'investment.php',
        data: {investinfo: true, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getBank: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getbank: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getSettings: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getSettings: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getAPI: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getAPI: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    random_color: function() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    addComma: function(x) {
      return x.toString().split('.')[0].length > 5 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
    },
    getMaxloan: function(income) {
      if(income <= 15000) {
        return 100000;
      }
      else if(income > 15000 && income <= 30000) {
          return 200000;
      }
      else if(income > 30000 && income <= 50000) {
          return 300000;
      }
      else if(income > 50000 && income <= 75000) {
          return 400000;
      }
      else if(income > 75000 && income <= 100000) {
          return 500000;
      }
      else if(income > 100000 && income <= 300000) {
          return 1000000;
      }
      else if(income > 300000 && income <= 500000) {
          return 2000000;
      }
      else if(income > 500000) {
          return 2500000;
      }
    },
    autoOTPcordova: function() {
      if (app.device.cordova) {
        var options = {
          otpLength: 6,
          delimiter : "Dear Customer,",
          senderID : "RBSLTD",
          timeout: 60
        };
        //app.dialog.progress('Fetching OTP...');
        function successCallback(otp) {
          app.dialog.close();
          $$('#ac-otp').val(otp);
          $$('.item-input-otp').addClass('item-input-focused, item-input-with-value');
          var uuid = app.methods.getDeviceUUID();
          const deviceDetector = new DeviceDetector();
          const userAgent = navigator.userAgent;
          if(app.device.cordova) {
            var deviceData = {
              cordova: device.cordova,
              model: device.model,
              platform: device.platform,
              uuid: device.uuid,
              version: device.version,
              manufacturer: device.manufacturer,
              isVirtual: device.isVirtual,
              serial: device.serial,
              sdkVersion: device.sdkVersion
            };
          } else {
            var deviceData = deviceDetector.parse(userAgent);
          }
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            {OTPver: true, mobile: localStorage.getItem('phone'), OTP: otp, device: deviceData, uuid: uuid},
            'json',
            function() {app.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                app.toast.show({
                  text: 'Logged in successfully!',
                  closeTimeout: 3000,
                });
                app.popup.close('.sheet-OTP--verification');
                localStorage.setItem('authToken', data.token);
                localStorage.setItem('isloggedIN', true);
                setTimeout(() => {
                  var view = app.views.current;
                  view.router.navigate('/');
                  app.dialog.close();
                }, 1000);
                $$('#ac-otp').val('');
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        function errorCallback(message) {
          app.dialog.close();
          console.log(message);
          app.toast.show({
            text: 'Something went wrong!',
            closeTimeout: 3000,
          });
        }
        smsOtpAutofill.startOTPListener(successCallback,errorCallback,options);
      }
    },
    generateCSRFToken: function() {
      var charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var token = '';
      for (var i = 0; i < 32; i++) {
          token += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return token;
    },
    deviceSave: function(deviceInfo) {
      var data = {
        'device': deviceInfo,
        'token': localStorage.getItem('authToken')
      }
      app.methods.request(
        baseURL+'device.php',
        JSON.stringify(data),
        'json',
        function() {},
        function(data) {
        },
        function() {
        }
      );
    },
    refreshUI: function(msg) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('phone');
      localStorage.removeItem('isloggedIN');
      localStorage.removeItem('amount');
      localStorage.removeItem('payment');
      localStorage.removeItem('maxloan');
      localStorage.setItem('isloggedIN', false);
      app.popup.close('#profile-popup');
      $$('.welcome__msg').addClass('hidden');
      $$('.profile-name').html('');
      $$('.profile-link').removeClass('popup-open');
      $$('.profile-link').removeAttr('data-popup');
      $$('.profile-link').attr('href','/login/');
      app.toast.show({
        text: msg,
        closeTimeout: 3000,
      });
    },
    imageUrlToBase64: function(imageUrl) {
      return new Promise((resolve, reject) => {
        fetch(imageUrl)
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.blob();
          })
          .then(blob => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
              };
          })
          .catch(error => reject(error));
      });
    },
  },
  // App routes
  routes: routes,



  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        //check if new update is availble
        $.getJSON('https://phonetouchfinance.in/update.json', function(data) {
            const remoteVersion = data.app.version.code;
            (async () => {
              var installedVersion = (await ApkUpdater.getInstalledVersion()).version.code;
              //console.log(installedVersion);
              if (remoteVersion > installedVersion) {
                //update available
                f7.popup.open('.app-update--live');
              }
            })();
        });
        //download update
        $$('.update-apk__btn').click(function () {
          (async () => {
            await ApkUpdater.download(
                'https://phonetouchfinance.in/apk/phonetouchfinance.apk',
                {
                  onDownloadProgress: function (e) {
                    console.log(e);
                    f7.dialog.progress('Downloading...');
                  },
                }
            );
            await ApkUpdater.install();
          })();
        });
        //push notifications
        document.addEventListener('deviceready', OneSignalInit, false);
        function OneSignalInit() {
            // Uncomment to set OneSignal device logging to VERBOSE  
            // window.plugins.OneSignal.setLogLevel(6, 0);
            
            // NOTE: Update the setAppId value below with your OneSignal AppId.
            window.plugins.OneSignal.setAppId("73065648-c668-4f43-b88e-c59db41abd00");
            window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
              console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
            });
            //Prompts the user for notification permissions.
              //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
              window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
                console.log("User accepted notifications: " + accepted);
            });
            var deviceID = device.uuid;
            window.plugins.OneSignal.setExternalUserId(deviceID);
            localStorage.setItem('uuid', deviceID);
            //console.log(deviceID);
        }
        //handle backbutton
        document.addEventListener("backbutton", function() {
          var leftp = f7.panel.left && f7.panel.left.opened;
          var rightp = f7.panel.right && f7.panel.right.opened;
          if ( leftp || rightp ) {
            f7.panel.close();
              return false;
          }
          var comp = ['actions','popover','picker','sheet','popup'].filter(function(itm){
            if(f7[itm].get()){
              f7[itm].close();
              return itm;
            }
          });
          if (!comp.length) {
            var view = f7.views.main;
            view.router.back();
          }
        }, false);
        cordovaApp.init(f7);
      } else {
        var uuid = new DeviceUUID().get();
        localStorage.setItem('uuid', uuid);
      }
    },
    //APP PAGE INIT
    pageInit: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        getInternetConnectionStatus(f7);
      } else {
        getInternetConnectionStatus(f7);
        if ($(window).width() > 600) {
          $$('.page-content').empty();
          $$(".page-content").append('<div class="block text-align-center">\
          <img class="" src="assets/icons/no-pc.png" width="350"/>\
          <div class="fw-600 text-color-red m-auto" style="font-size: 18px; width: 100%;">We want to make your experience as smooth as possible! Right now, our website isn\'t available in desktop mode. Please use your mobile browser for the best experience.</div>\
          </div>');
          $$('.navbar').empty();
          $$('.title').html('');
          $$('.title-large-text').html('');
          $$('.panel-open').empty();
          $$('.toolbar').empty();
        }
        $(window).resize(function() {
          windowResize()
        });
        if(curr_env == 'production') {
          //disable f12 and right click
          $(document).keydown(function (event) {
            if (event.keyCode == 123) { // Prevent F12
                return false;
            } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
                return false;
            }
          });
          $(document).on("contextmenu", function (e) {        
            e.preventDefault();
          });
        }
      }
      //show title on page scroll
      var pageContent = document.getElementById('page-content');
      if (pageContent) {
        // Add an event listener to the pageContent element
        pageContent.addEventListener('scroll', function(event) {
          // Get the scroll position
          var scrollTop = pageContent.scrollTop;
          // Update the title based on scroll position
          if (scrollTop > 10) { // Example threshold, adjust as needed
            $$('.title').removeClass('hidden');
          } else {
            $$('.title').addClass('hidden');
          }
        });
      } else {
        console.log("Element with class 'page-content' not found");
      }
      //get current page name
      var pageName = f7.views.main.router.url.split('/')[1];
      if(pageName != 'login') {
        //close popup
        f7.popup.close('.sheet-forget--pass');
        f7.popup.close('.sheet-forget--otp');
        f7.popup.close('.sheet-OTP--verification');
      }
      if(pageName == 'register') {
        f7.popup.close('.sheet-OTP--verification');
      }
      if(pageName != 'investment-details') {
        f7.sheet.close('.investcloseSheet');
        f7.sheet.close('.investDetailsSheet');
      }
      if(pageName != 'withdraw') {
        f7.sheet.close('.payout__sheetModal');
        f7.sheet.close('.autoWithdraw--Sheet');
      }
      if(pageName != 'renewal-details') {
        f7.sheet.close('.renewalSheet');
      }
      if(pageName != 'invest-form') {
        f7.sheet.close('.showKycSheet');
        f7.sheet.close('.showBankSheet');
      }
      if(pageName != 'investment-dashboard') {
        f7.popup.close('.importInvest--Sheet');
      }
      f7.methods.getUserData(function(data) {
        if(data.status == 'blocked') {
          app.views.current.router.navigate({name: 'Home'});
          localStorage.removeItem('authToken');
          localStorage.removeItem('phone');
          localStorage.removeItem('isloggedIN');
          localStorage.removeItem('amount');
          localStorage.removeItem('payment');
          localStorage.removeItem('phone');
          localStorage.removeItem('maxloan');
          localStorage.setItem('isloggedIN', false);
          app.popup.close('#profile-popup');
          $$('.welcome__msg').addClass('hidden');
          $$('.profile-name').html('');
          $$('.profile-link').removeClass('popup-open');
          $$('.profile-link').removeAttr('data-popup');
          $$('.profile-link').attr('href','/login/');
          f7.toast.show({
            text: 'Your account has been suspended and logged out from this device.',
            closeTimeout: 3000,
          });
          var router = app.views.current.router;
          router.navigate(router.currentRoute.url, {
            reloadCurrent: true,
            ignoreCache: true,
          });
        }
      });
      //print current year
      var date = new Date();
      var res = date.getFullYear();
      $$('.getCurrYear').html(res);
      $$('.app-version').html('Version '+app_version);
      //logout
      $$(document).off('click', '.logout');
      $$(document).on('click', '.logout' ,function() {
        app.methods.request(
          baseURL+'ajaxAuthentication.php',
          {logout: true, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')},
          'json',
          function() {app.dialog.progress('Logging out...');},
          function(data) {
            app.dialog.close();
            if(data.status == 'success') {
              localStorage.removeItem('authToken');
              localStorage.removeItem('phone');
              localStorage.removeItem('isloggedIN');
              localStorage.removeItem('amount');
              localStorage.removeItem('payment');
              localStorage.removeItem('maxloan');
              localStorage.setItem('isloggedIN', false);
              app.popup.close('#profile-popup');
              $$('.welcome__msg').addClass('hidden');
              $$('.profile-name').html('');
              $$('.profile-link').removeClass('popup-open');
              $$('.profile-link').removeAttr('data-popup');
              $$('.profile-link').attr('href','/login/');
              f7.toast.show({
                text: 'Logged out from this device!',
                closeTimeout: 3000,
              });
              var router = app.views.current.router;
              router.navigate(router.currentRoute.url, {
                reloadCurrent: true,
                ignoreCache: true,
              });
            } else {
              f7.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          },
          function() {
            app.dialog.close();
            f7.toast.show({
              text: 'Something went wrong, please try again!',
              closeTimeout: 3000,
            });
          }
        );
      });
      //OTP verification
      $(document).off('click', '.acBTNotp');
      $(document).on('click','.acBTNotp',function(){
        if (!$$('#ac-otp')[0].checkValidity()) {
          console.log('Check Validity!');
        } else {
          var value = $$('#ac-otp').val();
          OTPverification(value);
        }
      });
      function OTPverification(otp) {
        var uuid = f7.methods.getDeviceUUID();
        const deviceDetector = new DeviceDetector();
        const userAgent = navigator.userAgent;
        if(f7.device.cordova) {
          var deviceData = {
            cordova: device.cordova,
            model: device.model,
            platform: device.platform,
            uuid: device.uuid,
            version: device.version,
            manufacturer: device.manufacturer,
            isVirtual: device.isVirtual,
            serial: device.serial,
            sdkVersion: device.sdkVersion
          };
        } else {
          var deviceData = deviceDetector.parse(userAgent);
        }
        f7.methods.request(
          baseURL+'/ajaxAuthentication.php',
          {OTPver: true, mobile: localStorage.getItem('phone'), OTP: otp, device: deviceData, uuid: uuid},
          'json',
          function() {f7.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
          function(data) {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            if(data.status == 'success') {
              f7.toast.show({
                text: 'Logged in successfully!',
                closeTimeout: 3000,
              });
              f7.popup.close('.sheet-OTP--verification');
              localStorage.setItem('authToken', data.token);
              localStorage.setItem('isloggedIN', true);
              setTimeout(() => {
                var view = f7.views.current;
                view.router.navigate('/');
                f7.dialog.close();
              }, 1000);
              //reset otp field
              $$('#ac-otp').val('');
            }
            else {
              f7.toast.show({
                text: data.msg,
                closeTimeout: 3000,
              });
            }
          },
          function() {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            f7.toast.show({
              text: 'Something went wrong!',
              closeTimeout: 3000,
            });
          }
        );
      }
      //resend otp
      $(document).off('click', '.resendOTPac');
      $(document).on('click', '.resendOTPac', function() {
        var type = $$(this).attr('data-type');
        if(type == 'account') {
          var phone = localStorage.getItem('phone');
          var verID = 'account';
          var formData = {OTPresend: true, phone: phone, type: type};
          var url = baseURL+'/ajaxAuthentication.php';
        } else if(type == 'invest') {
          var refID = $$('.sheet-OTP--verification--ref').html();
          var verID = $('#verificationID').val();
          var formData = {OTPresend: true, refID: refID, type: type, verID: verID, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')};
          var url = baseURL+'/investment.php';
        } else {
        }
        f7.methods.request(url,
        formData,
        'json',
        function() {f7.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
        function(data) {
          $$('.button').removeClass('disabled');
          f7.dialog.close();
          if(data.status == 'success') {
            f7.toast.show({
              text: data.msg,
              closeTimeout: 3000,
            });
            if(type == 'account') {
              app.methods.autoOTPcordova();
            }
          }
          else {
            f7.toast.show({
              text: data.msg,
              closeTimeout: 3000,
            });
          }
        },
        function() {
          $$('.button').removeClass('disabled');
          f7.dialog.close();
          f7.toast.show({
            text: 'Failed to Connect',
            closeTimeout: 3000,
          });
        }
        );
      });
      //not editable data dialog
      $(document).off('click', '.not__editable');
      $(document).on('click', '.not__editable', function() {
        f7.dialog.alert('Please contact support@phonetouchfinance.in to update this data.','Warning!');
      });
      //edit profile
      $(document).off('click', '.editProfile');
      $(document).on('click', '.editProfile', function() {
        f7.popup.open('#editProfile-popup');
        f7.popup.close('#profile-popup');
      });
      //save data
      $(document).off('click', '.update__profile');
      $(document).on('click', '.update__profile', function() {
        if (!$$('#editProfile')[0].checkValidity()) {
          console.log('Check Validity!');
        } else {
          editProfileSave();
        }
      });
      //user login
      function editProfileSave() {
        var formdata = $('#editProfile').serialize();
        var data = formdata+'&editProfileSave=true&token='+localStorage.getItem('authToken');
        f7.methods.request(
          baseURL+'/ajaxAuthentication.php',
          data,
          'json',
          function() { f7.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
          function(data) {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            if(data.status == 'success') {
              f7.popup.close('#editProfile-popup');
              f7.methods.getUserData(function(data) {
                $$('.profile-name').html(data.name);
              });
              f7.toast.show({
                text: 'Profile information updated!',
                closeTimeout: 3000,
              });
              //reset form
              $$('#editProfile')[0].reset();
            }
            else {
              f7.toast.show({
                text: data.msg,
                closeTimeout: 3000,
              });
            }
          },
          function() {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            f7.toast.show({
              text: 'Something went wrong!',
              closeTimeout: 3000,
            });
          }
        );
      }
      $$('#terms-popup, #policy-popup').on('popup:open', function (e, popup) {
        f7.popup.close('#about-popup');
      });
      /*
      |------------------------------------------------------------------------------
      | Get Internet Connection Status
      |------------------------------------------------------------------------------
      */
      function getInternetConnectionStatus(f7) {
        window.addEventListener('online', function() {
          f7.toast.show({
            text: 'Welcome back, connected to Internet!',
            position: 'bottom',
            closeTimeout: 3000
          });
        });
        window.addEventListener('offline', function() {
          f7.toast.show({
            text: 'No Internet connection, please check your network!',
            position: 'bottom',
            closeButton: true,
            closeButtonText: 'Turn on',
            closeButtonColor: 'yellow'
          });
          $$('.toast-button').on('click', function() {
              if (window.cordova && window.cordova.plugins.settings) {
                    console.log('openNativeSettingsTest is active');
                    window.cordova.plugins.settings.open("wifi", function() {
                        console.log('opened settings');
                      },
                      function () {
                          console.log('failed to open settings');
                      }
                    );
                } else {
                    console.log('openNativeSettingsTest is not active!');
                }
          });
        });
      };
      function windowResize() {
        if ($(window).width() > 600) {
          $$('.page-content').empty();
          $$(".page-content").append('<div class="block text-align-center">\
          <img class="" src="assets/icons/no-pc.png" width="350"/>\
          <div class="fw-600 text-color-red m-auto text-align-justify" style="font-size: 18px; width: 100%;">We want to make your experience as smooth as possible! Right now, our website isn\'t available in desktop mode. Please use your mobile browser for the best experience.</div>\
          </div>');
          $$('.navbar').empty();
          $$('.title').html('');
          $$('.title-large-text').html('');
          $$('.panel-open').empty();
          $$('.toolbar').empty();
        } else {
          //location.reload();
        }
      }
    },
  },
});
