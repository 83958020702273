export default {
  id: 'd64f96284d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page form-page">\r\n      <div class="navbar no-shadow paymentPage">\r\n        <div class="navbar-bg"></div>\r\n        <div class="navbar-inner">\r\n          <div class="left">\r\n            <a href="#" class="link back ripple-color-red">\r\n              <img class="" src="assets/icons/back.png" width="35"/>\r\n            </a>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    <div class="page-content">\r\n        <div class="block-conatiner">\r\n          <div class="reg-txt-content">\r\n            <h2 class="margin-left fw-bold">No Notification!</h2>\r\n          </div>\r\n          <div class="text-center">\r\n            <img src="assets/gif/notification.gif" style="margin-top: 50px; width: 80%"/>\r\n          </div>\r\n          <div class="block">\r\n            <h3 class="text-color-blue-light fs-12 fw-600"></h3>\r\n            <a href="/" class="w-100 col m-auto button button-fill button-large button-fill bg-color-red-light text-color-red">Back to home</a>\r\n          </div>\r\n        </div>\r\n    </div>\r\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};